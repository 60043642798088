import { PutStoreBayRequest, StoreBayResponse } from '@api/types/generated';
import { queryClient } from '@api/query-client';
import { storeBaysApi } from '@api/services/storeBays';
import {
  StoreBayFormData,
  StoreBayModalProps,
} from '@components/organisms/storeBayModal/storeBayModal';
import { TableHeader } from '@components/pages/realograms/fragments/realogramsDirectoryTable/tableHeader';
import { useModal } from '@hooks/useModal';
import { selectUser } from '@reducers/auth/selectors';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { createPortal } from 'react-dom';
import { objectToSnake } from 'ts-case-convert';
import { RealogramSharePermissionProps } from 'types/sharePermission';
import { StoreBay } from 'types/storeBay';
import { DeleteStoreBayDialog } from './deleteStoreBayDialog';
import { StoreBayTableCells } from './storeBayTableCells';
import { getStoreBayColumns } from './storeBayTableHeaderData';
import { DataTable } from './table';
import { TableRowWrapper } from './tableRowWrapper';

type Props = {
  storeId: number;
  canEditStoreBay: boolean;
  canReadStoreBay: boolean;
  isNoHit: boolean;
  handleFavoriteClick: (id: string, isFavorite: boolean) => void;
  handleSharePermission: (item: RealogramSharePermissionProps) => void;
};

export const storeBaysQueryKey = 'store_bays';

export const StoreBaysTable: FC<Props> = ({
  storeId,
  canEditStoreBay,
  canReadStoreBay,
  isNoHit,
  handleFavoriteClick,
  handleSharePermission,
}) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [storeBayIdToDelete, setStoreBayIdToDelete] = useState(0);

  const {
    isLoading,
    isFetching,
    data: storeBays,
  } = useQuery({
    queryKey: [storeBaysQueryKey, storeId],
    queryFn: () => storeBaysApi.getStoreBays(storeId),
    select: (data) => data.store_bays,
  });

  const { mutateAsync: updateStoreBay } = useMutation<
    StoreBayResponse,
    Error,
    PutStoreBayRequest & { id: number }
  >({
    mutationFn: ({ id, ...params }) => storeBaysApi.updateStoreBay(id, params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [storeBaysQueryKey],
      });
    },
  });

  const { mutateAsync: deleteStoreBay } = useMutation({
    mutationFn: storeBaysApi.deleteStoreBay,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [storeBaysQueryKey],
      });
    },
  });

  const isEmpty = !storeBays?.length && !isLoading;
  const isDisplayLoadingSkeleton = isLoading || isFetching;
  const columns = getStoreBayColumns();

  const { showModal: showStoreBayModal } = useModal<StoreBayModalProps>(
    'storeBayModal',
    '什器編集'
  );
  const handleEditStoreBay = (storeBay: StoreBay) => {
    const onSubmit = async (
      { sortOrder, storeSectionMasterId, ...props }: StoreBayFormData,
      onSuccess: VoidFunction
    ) => {
      dispatch(updateLoadingIndicatorState(true));
      try {
        await updateStoreBay({
          ...objectToSnake(props),
          sort_order: +sortOrder,
          store_section_master_id: storeSectionMasterId
            ? +storeSectionMasterId
            : undefined,
          // eslint-disable-next-line react/prop-types -- prop types is outdated
          bay_plan_id: parseInt(props.bayPlanId ?? ''),
          id: storeBay.id,
        });
        onSuccess();
        dispatch(
          openToast({
            type: 'success',
            message: '什器を変更しました。',
          })
        );
      } catch (error) {
        return Promise.reject(error);
      } finally {
        dispatch(updateLoadingIndicatorState(false));
      }
    };

    showStoreBayModal({
      storeBay,
      onSubmit,
      isEdit: true,
    });
  };

  const handleDeleteStoreBay = async () => {
    dispatch(updateLoadingIndicatorState(true));
    try {
      await deleteStoreBay(storeBayIdToDelete);
      setDeleteDialogOpen(false);
      dispatch(
        openToast({
          type: 'success',
          message: '什器を削除しました。',
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  return (
    <>
      <DataTable<StoreBay>
        handleEndReached={() => void 0}
        isRefetching={false}
        isEmpty={isEmpty}
        isDisplayLoadingSkeleton={isDisplayLoadingSkeleton}
        data={storeBays}
        columns={columns}
        isNoHit={isNoHit}
        tableHeaderContent={<TableHeader columns={columns} />}
        itemContent={(_, item) => {
          const isStarred = user?.id === item.favorite?.owner_id;
          return (
            <StoreBayTableCells
              storeBay={item}
              canEditStoreBay={canEditStoreBay}
              canReadStoreBay={canReadStoreBay}
              handleDelete={() => {
                setDeleteDialogOpen(true);
                setStoreBayIdToDelete(item.id);
              }}
              handleEdit={() => handleEditStoreBay(item)}
              handleFavoriteClick={() =>
                handleFavoriteClick(item.directory_id, isStarred)
              }
              handleSharePermission={() =>
                handleSharePermission({
                  directoryId: item.directory_id,
                  type: 'directory',
                })
              }
              isStarred={isStarred}
            />
          );
        }}
        renderTableRow={(props) => {
          return (
            <TableRowWrapper
              // TODO: Add handler
              handleRowClick={() => void 0}
              {...props}
              item={{
                // eslint-disable-next-line react/prop-types -- prop types is outdated
                ...props.item,
                type: 'directory',
              }}
            />
          );
        }}
      />
      {createPortal(
        <DeleteStoreBayDialog
          open={deleteDialogOpen}
          handleClickCancelButton={() => setDeleteDialogOpen(false)}
          handleClickConfirmButton={handleDeleteStoreBay}
        />,
        document.body
      )}
    </>
  );
};
