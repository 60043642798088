import { ProductCandidate, ZoomablePlanogram } from '@components/organisms';
import { NotPermittedModal } from '@components/organisms/NotPermittedModal/NotPermittedModal';
import { CompartmentsTable } from '@components/organisms/compartmentsTable/compartmentsTable';
import { HeatMap } from '@components/organisms/heatMap/heatMap';
import { PlanogramDetailHeader } from '@components/organisms/planogramDetailHeader/planogramDetailHeader';
import { PlanogramOverview } from '@components/organisms/planogramOverview/planogramOverview';
import { ShelfDetailTabs } from '@components/organisms/shelfDetailTabs/shelfDetailTabs';
import { PlanButtonGroup } from '@components/pages/planogramDetail/fragments/planButtonGroup';
import { useRerenderingDetails } from '@hooks/rerenderingComponents';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useBrowserOperate } from '@hooks/useBrowserOperate';
import { useEstimatedProfit } from '@hooks/useEstimatedProfit';
import { useInitSelectedPlanogram } from '@hooks/useInitSelectedPlanogram';
import { useOrganizationStatuses } from '@hooks/useOrganizationStatuses';
import { usePlanogramPlan } from '@hooks/usePlanogramPlan';
import { useUpdateUrlQueryParamsOfDetailPages } from '@hooks/useUpdateUrlQueryParamsOfDetailPages';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { useZoomController } from '@hooks/useZoomController';
import { Box } from '@mui/material';
import { selectComparisonItemModal } from '@reducers/comparisonItemModal/selectors';
import {
  updateDetailMode,
  updateDetailView,
  updateProductPosition,
  updateProductTagTab,
  updateProfitTab,
  updateRateValue,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { updateSelectedRealogramItem } from '@reducers/realogramCandidate';
import { useListProductsBulkQuery } from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import {
  embed,
  formatNumberToYen,
  fullHeight,
  planogramRightSideHeight,
  profitTabSales,
} from '@utils/const';
import { getTextDateStatistic } from '@utils/date';
import {
  calcPlanStatistics,
  getNextPlanogramItem,
  getPrevPlanogramItem,
  getProductsLayout,
  isSelectedCompartment,
} from '@utils/planogram';
import { hasProductTag } from '@utils/product';
import { Refs, scrollToCenterOfList } from '@utils/realogram';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { theme } from 'theme';
import {
  Product,
  ProductTag,
  ProfitTab,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import {
  Planogram,
  PlanogramPlan,
  PlanogramProductCompartment,
  PlanogramProductCompartmentList,
  ProductPosition,
} from 'types/planogram';
import { ProductReportProduct } from 'types/products';
import { RealogramSelectedItem } from 'types/realogram';
import { ProductTagValue } from 'types/statistics';
import { Comparison } from '../fragments/comparison';

type Props = {
  planogram?: Planogram;
  handleNavigateView: () => void;
  isForbidden?: boolean;
  embedParam: string;
};

type PlanogramProductCompartmentExpand = PlanogramProductCompartment & {
  position: ProductPosition;
};

const getProductsLayoutByView = (
  plan: PlanogramPlan,
  view: ShelfDetailView,
  productTag: ProductTag,
  products?: Product[],
  productSales?: ProductReportProduct[]
) => {
  const productsLayout = plan.products_layout.slice().reverse();

  const productMap = products?.reduce((acc, product) => {
    acc[product.id] = product;
    return acc;
  }, {} as Record<string, Product>);

  const productSalesMap = productSales?.reduce((acc, product) => {
    acc[product.product_id] = product;
    return acc;
  }, {} as Record<string, ProductReportProduct>);

  const mapCompartment = (
    compartment: PlanogramProductCompartment,
    shelfIndex: number,
    index: number
  ): PlanogramProductCompartmentExpand => {
    const position = {
      indexX: 0,
      indexY: productsLayout.length - shelfIndex - 1,
      subPosition: {
        indexX: index,
        indexY: 0,
      },
    };
    return {
      ...compartment,
      position,
    };
  };

  const filterCompartments = (
    row: PlanogramProductCompartmentList['row'],
    shelfIndex: number
  ) => {
    return row.reduce((filtered, compartment, index) => {
      const product = productMap?.[compartment.product_id];
      const isTagged = hasProductTag(productTag, product?.detail?.tags);
      if (isTagged) {
        const data = mapCompartment(compartment, shelfIndex, index);
        filtered.push(data);
      }

      return filtered;
    }, [] as PlanogramProductCompartmentExpand[]);
  };

  const filterProfitCompartments = (
    row: PlanogramProductCompartmentList['row'],
    shelfIndex: number
  ) => {
    return row.reduce((filtered, compartment, index) => {
      const product = productMap?.[compartment.product_id];
      const salesProduct = productSalesMap?.[compartment.product_id];
      const isSameProduct = product?.id === salesProduct?.product_id;
      if (isSameProduct) {
        const data = mapCompartment(compartment, shelfIndex, index);
        filtered.push(data);
      }

      return filtered;
    }, [] as PlanogramProductCompartmentExpand[]);
  };
  const getNewRow = (
    row: PlanogramProductCompartmentList['row'],
    shelfIndex: number,
    view: ShelfDetailView
  ) => {
    switch (view) {
      case 'productFlag':
        return filterCompartments(row, shelfIndex);
      case 'profit':
        return filterProfitCompartments(row, shelfIndex);
      default:
        return row.map((compartment, index) =>
          mapCompartment(compartment, shelfIndex, index)
        );
    }
  };

  return productsLayout.map(({ row, ...rest }, shelfIndex) => {
    const newRow = getNewRow(row, shelfIndex, view);
    return {
      ...rest,
      row: newRow,
    };
  });
};

const getCurrentCompartmentPosition = (
  productsLayout: ReturnType<typeof getProductsLayoutByView>,
  productPosition: ProductPosition,
  productId?: number
): ProductPosition | undefined => {
  const { indexY, subPosition } = productPosition;
  const shelf = productsLayout.find(({ row }) =>
    row.some(
      (compartment) =>
        compartment.product_id === productId &&
        compartment.position.indexY === indexY &&
        compartment.position.subPosition.indexX === subPosition.indexX
    )
  );

  if (!shelf) return undefined;

  const shelfIndex = productsLayout.reverse().indexOf(shelf);

  const compartmentIndex = shelf.row.findIndex(
    (compartment) =>
      compartment.product_id === productId &&
      compartment.position.subPosition.indexX === subPosition.indexX
  );

  return {
    indexX: 0,
    indexY: shelfIndex,
    subPosition: {
      indexX: compartmentIndex,
      indexY: 0,
    },
  };
};

const productCandidatePY = 2;

export const ShelfPlanogramDetail: FC<Props> = ({
  planogram,
  handleNavigateView,
  isForbidden,
  embedParam,
}) => {
  const dispatch = useAppDispatch();
  const { updateQueryParameter, removeQueryParameter } = useUrlQueryParams();
  const listItemRefs = useRef<Refs>(new Map());
  const {
    zoomScale,
    handleDecrementZoom,
    handleIncrementZoom,
    handleTriggerZoom,
  } = useZoomController();
  const [profitTab, setProfitTab] = useState<ProfitTab>(profitTabSales);
  const [initRerenderingHeaderStatus, setInitRerenderingHeaderStatus] =
    useState(false); // 初回描画：ヘッダータブ更新フラグ
  const [initRerenderingSelectedProduct, setInitRerenderingSelectedProduct] =
    useState(false); // 初回描画：選択中のアイテム情報更新フラグ
  const [
    initRerenderingSelectedProductPosition,
    setInitRerenderingSelectedProductPosition,
  ] = useState(false); // 初回描画：選択中のアイテム位置情報更新フラグ
  const [initUpdateUrl, setInitUpdateUrl] = useState(false); // 初回描画：URLの更新フラグ
  const [bboxEnabled, setBboxEnabled] = useState(true);
  const { selectedProductCompartment, productPosition, rateValue } =
    useAppSelector(selectPlanogramEditorState);
  const { selectedDirectoryId } = useAppSelector((state) => state.Planogram);
  const { currentSelectedItemId, currentSelectedType } = useAppSelector(
    selectComparisonItemModal
  );
  const { plan, forget } = usePlanogramPlan(planogram?.plan);
  const {
    updateModeQueryParams,
    updateAnalyticsQueryParams,
    updateEvaluationQueryParams,
  } = useUpdateUrlQueryParamsOfDetailPages();

  const {
    detailView: view,
    detailMode: mode,
    productTag,
  } = useAppSelector(selectPlanogramEditorState);
  const isComparisonMode = mode === 'comparison';

  const productIds = plan.products_layout
    .flatMap(({ row }) => row.map(({ product_id }) => product_id))
    .sort()
    .join(',');
  const { data: productsBulk, isLoading } = useListProductsBulkQuery(
    { productIds: productIds, shape: true, detail: true },
    { skip: productIds === '' }
  );

  const resetSelectedProduct = useCallback(() => {
    dispatch(updateSelectedProductCompartment(undefined));
    dispatch(updateProductPosition(undefined));
    dispatch(updateSelectedRealogramItem(undefined));
  }, [dispatch]);

  const handleClickListItem = (
    position: ProductPosition,
    product?: Product
  ) => {
    if (isSelectedCompartment(position, productPosition)) {
      removeQueryParameter('item');
      resetSelectedProduct();
      return;
    }
    dispatch(updateSelectedProductCompartment(product));
    dispatch(updateProductPosition(position));

    if (product) {
      updateQueryParameter('item', String(product.id));
    } else {
      removeQueryParameter('item');
    }
  };

  const statistic = useMemo(
    () => calcPlanStatistics(plan, view, productTag, productsBulk?.products),
    [plan, productTag, view, productsBulk]
  );

  const { productTagValues } = useMemo(() => {
    const productTagValues: ProductTagValue[] = [
      {
        item: 'new_products_first_week',
        value: statistic?.productFlag.totalNewFacesFirstWeek ?? '',
      },
      {
        item: 'new_products_second_week',
        value: statistic?.productFlag.totalNewFacesSecondWeek ?? '',
      },
      {
        item: 'base_product',
        value: statistic?.productFlag.totalMainFaces ?? '',
      },
      {
        item: 'sales_ended',
        value: statistic?.productFlag.totalRecomendedToCancelationFaces ?? '',
      },
      {
        item: 'not_3D_scanned',
        value: statistic?.productFlag.totalNot3DScannedFaces ?? '',
      },
    ];
    return { productTagValues };
  }, [
    statistic?.productFlag.totalNewFacesFirstWeek,
    statistic?.productFlag.totalNewFacesSecondWeek,
    statistic?.productFlag.totalMainFaces,
    statistic?.productFlag.totalRecomendedToCancelationFaces,
    statistic?.productFlag.totalNot3DScannedFaces,
  ]);

  const {
    isViewQueryParams,
    selector,
    operate,
    data: { isLoadingTenant, canReadDemo, isTenantSalesAnalytics },
  } = useRerenderingDetails();

  // item情報を取得
  const { isEmpty, position, productsSelected } =
    operate.getSelectedItemOfPlanogram(
      productsBulk?.products,
      planogram?.plan ? getProductsLayout(planogram.plan) : []
    );
  const { planogramEstimatedData } = useEstimatedProfit({ planogram });
  const checkHasSelectedItem = useCallback(
    (view?: ShelfDetailView, productTag?: ProductTag) => {
      const filteredRows = plan.products_layout.flatMap(({ row }) =>
        row.map((compartment) => {
          const product = productsBulk?.products?.find(
            (product) => product.id === compartment.product_id
          );

          const shouldIncludeProduct =
            view !== 'productFlag' ||
            (productTag
              ? hasProductTag(productTag, product?.detail?.tags)
              : false);
          return shouldIncludeProduct ? product : null;
        })
      );

      const products = filteredRows?.filter(Boolean);
      const hasSelectedItem = (products || []).some(
        (product) => product?.id === selectedProductCompartment?.id
      );

      return hasSelectedItem;
    },
    [
      plan.products_layout,
      productsBulk?.products,
      selectedProductCompartment?.id,
    ]
  );

  // 初回描画：ヘッダータブ
  useEffect(() => {
    if (embedParam !== embed) {
      if (initRerenderingHeaderStatus || isLoading || isLoadingTenant) return;

      if (selector.modeQueryParams) {
        dispatch(updateDetailMode(selector.modeQueryParams));
      }
      // view=layout
      if (selector.viewQueryParams === 'default') {
        dispatch(updateDetailView(selector.viewQueryParams));
      }
      // view=attribute
      else if (selector.viewQueryParams === 'productFlag') {
        dispatch(updateDetailView(selector.viewQueryParams));
        dispatch(
          updateProductTagTab(
            selector.attributeQueryParams
              ? selector.attributeQueryParams
              : 'new_products_first_week'
          )
        );
      }
      // view=analytics
      else if (
        selector.viewQueryParams === 'profit' &&
        isTenantSalesAnalytics
      ) {
        const profitValue = selector.profitTab
          ? selector.profitTab
          : profitTabSales;
        dispatch(updateDetailView(selector.viewQueryParams));
        setProfitTab(profitValue);

        // Update profit value in comparison mode when back-browsing
        if (selector.modeQueryParams === 'comparison') {
          dispatch(updateProfitTab(profitValue));
        }
      }
      // view=evaluation
      else if (selector.viewQueryParams === 'rate' && canReadDemo) {
        dispatch(updateDetailView(selector.viewQueryParams));
        dispatch(
          updateRateValue(
            selector.evaluationQueryParams
              ? selector.evaluationQueryParams
              : '評価OK'
          )
        );
      }
      setInitRerenderingHeaderStatus(true);
    }
  }, [
    canReadDemo,
    dispatch,
    embedParam,
    initRerenderingHeaderStatus,
    isLoading,
    isLoadingTenant,
    isTenantSalesAnalytics,
    isViewQueryParams,
    operate,
    selector,
  ]);

  const onInitSelectedProductCompleted = useCallback(() => {
    if (!productsSelected?.length) {
      resetSelectedProduct();
    }
    setInitRerenderingSelectedProduct(true);
  }, [productsSelected?.length, resetSelectedProduct]);

  useInitSelectedPlanogram({
    planogramPlan: plan,
    initSelectedData: {
      isSkipInit:
        !productsSelected ||
        initRerenderingSelectedProduct ||
        isLoading ||
        isComparisonMode,
      view,
      productTag,
      onInitCompleted: onInitSelectedProductCompleted,
    },
  });

  // 初回描画：選択中のアイテム位置情報
  useEffect(() => {
    if (initRerenderingSelectedProductPosition || isLoading || isEmpty) return;
    dispatch(
      updateProductPosition({
        indexX: 0,
        indexY: position.indexY as number,
        subPosition: {
          indexX: position.subIndexX as number,
          indexY: 0,
        },
      })
    );
    setInitRerenderingSelectedProductPosition(true);
  }, [
    dispatch,
    initRerenderingSelectedProductPosition,
    isEmpty,
    isLoading,
    position.indexY,
    position.subIndexX,
  ]);

  // 初回描画のみ：URLの更新
  useEffect(() => {
    if (
      initUpdateUrl ||
      !initRerenderingHeaderStatus ||
      isLoading ||
      isLoadingTenant ||
      !productsSelected ||
      isComparisonMode
    )
      return;

    const isProduct = !!productsSelected.length;
    operate.updateUrlQueryParams({
      ...selector,
      isProduct,
    });
    setInitUpdateUrl(true);
  }, [
    initRerenderingHeaderStatus,
    initUpdateUrl,
    isComparisonMode,
    isLoading,
    isLoadingTenant,
    operate,
    productsSelected,
    selector,
  ]);

  // ブラウザバックなどの操作時に発火する
  const updateStateByBrowserOperated = () => {
    setInitRerenderingHeaderStatus(false);
    setInitRerenderingSelectedProduct(false);
    setInitRerenderingSelectedProductPosition(false);
  };
  useBrowserOperate(updateStateByBrowserOperated);

  useEffect(() => {
    if (productPosition) {
      const key = JSON.stringify(productPosition);
      scrollToCenterOfList(listItemRefs, key);
    }
  }, [productPosition]);

  useEffect(() => {
    return () => {
      forget();
    };
  }, [forget]);

  useEffect(() => {
    dispatch(updateSelectedRealogramItem(undefined));
  }, [view, dispatch]);

  // 通常・比較モードの切り替え
  const handleChangeMode = (mode: ShelfDetailMode) => {
    if (mode) {
      const originPlanogramId = planogram?.link?.origin_planogram_id;
      const originRealogramCandidateId =
        planogram?.link?.origin_realogram_candidate_id;
      const profitValue = mode === 'default' ? profitTab : profitTabSales;
      dispatch(updateDetailMode(mode));
      dispatch(updateSelectedRealogramItem(undefined));
      const queryString =
        currentSelectedItemId && currentSelectedType
          ? `${currentSelectedType}-${currentSelectedItemId}`
          : originPlanogramId
          ? `plan-${originPlanogramId}`
          : originRealogramCandidateId
          ? `actual-${originRealogramCandidateId}`
          : '';
      updateModeQueryParams(mode, view, productTag, profitValue, rateValue, {
        selectedItem: queryString,
      });
    }
  };

  // 表示内容の切り替え
  const handleChangeView = (view: ShelfDetailView) => {
    if (!view) return;

    const profitValue = mode === 'default' ? profitTab : profitTabSales;
    const hasSelectedItem = checkHasSelectedItem(view, productTag);
    if (!hasSelectedItem) {
      resetSelectedProduct();
    }
    dispatch(updateDetailView(view));
    operate.updateUrlQueryParams(
      {
        viewQueryParams: view,
        isProduct: !!hasSelectedItem,
        attributeQueryParams: productTag,
        profitTab: profitValue,
        evaluationQueryParams: rateValue,
      },
      {
        replace: false,
      }
    );
  };

  // 商品属性の中の項目を更新
  const handleChangeProductTag = (productTag: ProductTag) => {
    const profitValue = mode === 'default' ? profitTab : profitTabSales;
    const hasSelectedItem = checkHasSelectedItem(view, productTag);
    if (!hasSelectedItem) {
      resetSelectedProduct();
    }
    dispatch(updateProductTagTab(productTag));
    operate.updateUrlQueryParams(
      {
        viewQueryParams: view,
        isProduct: !!hasSelectedItem,
        attributeQueryParams: productTag,
        profitTab: profitValue,
        evaluationQueryParams: rateValue,
      },
      {
        replace: false,
      }
    );
  };

  const selectNext = (
    productsLayout: PlanogramPlan['products_layout'],
    position?: ProductPosition
  ) => {
    if (!position) {
      return;
    }

    const { subPosition, indexY } = position;
    const productLayout = productsLayout.at(indexY);
    const productsLayoutByTag = getProductsLayoutByView(
      plan,
      view,
      productTag,
      productsBulk?.products,
      planogramEstimatedData?.estimate.products
    );
    const updatedPosition = {
      indexX: 0,
      indexY,
      subPosition: {
        indexX: subPosition.indexX + 1,
        indexY: 0,
      },
    };
    let product: Product | undefined;

    if (productLayout?.row.length === subPosition.indexX + 1) {
      if (position.indexY === 0) return;
      const { indexY, indexX, subPosition } = getNextPlanogramItem(
        productsLayout,
        position
      );
      const nextProductLayout = productsLayout.at(indexY);
      if (!nextProductLayout) return;
      updatedPosition.indexX = indexX;
      updatedPosition.indexY = indexY;
      updatedPosition.subPosition = subPosition;
      product = {
        id: nextProductLayout.row.at(0)?.product_id,
      } as Product;
    } else {
      product = {
        id: productsLayout
          .at(position.indexY)
          ?.row.at(position.subPosition.indexX + 1)?.product_id,
      } as Product;
    }
    if (!product.id) return;
    const productIsInProductsLayoutByTag = !!productsLayoutByTag.find((shelf) =>
      shelf.row.find((compartment) => compartment.product_id === product?.id)
    );
    if (!productIsInProductsLayoutByTag) {
      // Keep finding the product until it is found in the productsLayoutByTag
      selectNext(productsLayout, updatedPosition);
      return;
    }
    dispatch(updateSelectedProductCompartment(product));
    dispatch(updateProductPosition(updatedPosition));
    updateQueryParameter('item', String(product.id));
  };

  const selectPrev = (
    productsLayout: PlanogramPlan['products_layout'],
    position?: ProductPosition
  ) => {
    if (!position) {
      return;
    }
    const productsLayoutByTag = getProductsLayoutByView(
      plan,
      view,
      productTag,
      productsBulk?.products,
      planogramEstimatedData?.estimate.products
    );
    const updatedPosition = {
      indexX: 0,
      indexY: position.indexY,
      subPosition: {
        indexX: position.subPosition.indexX - 1,
        indexY: 0,
      },
    };
    let product: Product | undefined;

    if (position.subPosition.indexX === 0) {
      if (position.indexY === productsLayout.length - 1) return;
      const { indexY, indexX, subPosition } = getPrevPlanogramItem(
        productsLayout,
        position
      );
      const prevLastShelfBoard = productsLayout.at(indexY);
      const prevLastCompartmentIndex =
        (prevLastShelfBoard?.row.length ?? 0) - 1;
      updatedPosition.indexX = indexX;
      updatedPosition.indexY = indexY;
      updatedPosition.subPosition = subPosition;
      //次の段を跨ぐ場合
      product = {
        id: prevLastShelfBoard?.row.at(prevLastCompartmentIndex)?.product_id,
      } as Product;
    } else {
      product = {
        id: productsLayout
          .at(position.indexY)
          ?.row.at(position.subPosition.indexX - 1)?.product_id,
      } as Product;
    }
    const productIsInProductsLayoutByTag = !!productsLayoutByTag.find((shelf) =>
      shelf.row.find((compartment) => compartment.product_id === product?.id)
    );
    if (product.id && !productIsInProductsLayoutByTag) {
      // Keep finding the product until it is found in the productsLayoutByTag
      selectPrev(productsLayout, updatedPosition);
      return;
    }
    dispatch(updateSelectedProductCompartment(product));
    dispatch(updateProductPosition(updatedPosition));
    updateQueryParameter('item', String(product.id));
  };

  const shouldButtonBeDisabled = (value: 'prev' | 'next') => {
    if (!plan.products_layout.length || !productPosition) return true;
    const productsLayout = getProductsLayoutByView(
      plan,
      view,
      productTag,
      productsBulk?.products,
      planogramEstimatedData?.estimate.products
    );
    const position = getCurrentCompartmentPosition(
      productsLayout,
      productPosition,
      selectedProductCompartment?.id
    ) || {
      indexX: 0,
      indexY: productPosition.indexY,
      subPosition: {
        ...productPosition.subPosition,
      },
    };
    const { indexY, subPosition } = position;
    if (value === 'prev') {
      const findLastIndexCustom = (
        arr: {
          row: PlanogramProductCompartmentExpand[];
        }[],
        predicate: (shelf: {
          row: PlanogramProductCompartmentExpand[];
        }) => boolean
      ) => {
        for (let i = arr.length - 1; i >= 0; i--) {
          if (predicate(arr[i])) {
            return i;
          }
        }
        return -1; // 見つからなかった場合
      };

      const lastRowWithProductIndex = findLastIndexCustom(
        productsLayout,
        (shelf: { row: PlanogramProductCompartmentExpand[] }) =>
          shelf.row.length > 0
      );
      // findLastIndexが使えない端末があったのでforループで代用
      // const lastRowWithProductIndex = productsLayout.findLastIndex(
      //   (shelf) => shelf.row.length
      // );
      if (lastRowWithProductIndex < indexY) {
        return true;
      }
      return lastRowWithProductIndex === indexY && subPosition.indexX === 0;
    } else {
      const firstRowWithProductIndex = productsLayout.findIndex(
        (shelf) => shelf.row.length
      );
      if (
        firstRowWithProductIndex === -1 ||
        firstRowWithProductIndex > indexY
      ) {
        return true;
      }
      return (
        firstRowWithProductIndex === indexY &&
        subPosition.indexX >= (productsLayout.at(indexY)?.row.length || 0) - 1
      );
    }
  };

  const handleClickAwayPlanogram = () => {
    if (selectedProductCompartment && productPosition) {
      removeQueryParameter('item');
      resetSelectedProduct();
    }
  };
  const { start_date: startDateFromAPI, end_date: endDateFromAPI } =
    planogramEstimatedData?.estimate.summary.aggregation_period || {};
  const dateTerm = getTextDateStatistic(startDateFromAPI, endDateFromAPI);

  const { isLarger } = useBreakpoint();
  const { organizationStatus } = useOrganizationStatuses(
    planogram?.organization_status_id
  );
  const isPlan = !!plan;
  return (
    <Box
      component="div"
      height={fullHeight}
      display="flex"
      flexDirection="column"
    >
      <PlanogramDetailHeader
        handleChangeView={handleChangeView}
        handleChangeMode={handleChangeMode}
        planogram={planogram}
        hiddenElement={embedParam === embed}
        isShowAnalyticsButton={isTenantSalesAnalytics}
        organizationStatus={organizationStatus}
      />
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        {mode === 'comparison' ? (
          <Comparison
            planogram={planogram}
            products={productsBulk?.products}
            plan={plan}
            isTenantSalesAnalytics={isTenantSalesAnalytics}
            initRerenderingSelectedProduct={initRerenderingSelectedProduct}
            setInitRerenderingSelectedProduct={
              setInitRerenderingSelectedProduct
            }
            compareQueryParams={selector.compareQueryParams}
          />
        ) : (
          <>
            <Box
              component="div"
              position="absolute"
              top={24}
              zIndex={5}
              sx={{ transform: `translate(0px, 24px)` }}
            >
              <PlanButtonGroup
                bboxEnabled={bboxEnabled}
                handleChangeBboxEnabled={() => setBboxEnabled(!bboxEnabled)}
                handleNavigateView={handleNavigateView}
                handleIncrementZoom={handleIncrementZoom}
                handleDecrementZoom={handleDecrementZoom}
                handleTriggerZoom={handleTriggerZoom}
                zoomScale={zoomScale}
                isOrientationModalOpen={false}
                isEditor={false}
              />
            </Box>
            <Box
              component="div"
              display="flex"
              flexDirection={{ xs: 'column', breakpoint: 'row' }}
              overflow="hidden"
              flex={1}
            >
              <Box
                component="div"
                sx={{
                  flex: { xs: 1, breakpoint: 5 },
                  position: 'relative',
                  overflow: 'auto',
                  backgroundColor: theme.palette.shelf.backgroundTana,
                }}
              >
                <ZoomablePlanogram
                  bboxEnabled={bboxEnabled}
                  plan={plan}
                  scale={zoomScale}
                  handleClickAway={handleClickAwayPlanogram}
                />
                {view === 'profit' && (
                  <Box
                    component="div"
                    sx={{ position: 'absolute', bottom: 24, left: 24 }}
                  >
                    <HeatMap
                      start={formatNumberToYen(0)}
                      // eslint-disable-next-line no-magic-numbers -- formatNumberToYen
                      end={formatNumberToYen(20000)}
                    />
                  </Box>
                )}
              </Box>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: { breakpoint: 5.5 },
                  overflow: 'hidden',
                  borderLeft: `1px solid ${theme.palette.dividerBlack.light}`,
                  height: {
                    xs: planogramRightSideHeight,
                    breakpoint: 'auto',
                  },
                }}
              >
                <ShelfDetailTabs
                  productTag={productTag}
                  realogramDetailView={view}
                  handleChangeProductTag={handleChangeProductTag}
                  rateValue={rateValue}
                  handleChangeRateValue={(item) => {
                    dispatch(updateRateValue(item));
                    updateEvaluationQueryParams(item);
                  }}
                  rateValues={[]}
                  productTagValues={productTagValues}
                  hasSelected={!!selectedProductCompartment}
                  isPlan={isPlan}
                />

                {selectedProductCompartment ? (
                  <Box
                    component="div"
                    px={2}
                    py={isLarger ? productCandidatePY : 0}
                  >
                    <ProductCandidate
                      realogramDetailView={view}
                      shouldButtonBeDisabled={shouldButtonBeDisabled}
                      selectNext={() =>
                        selectNext(plan.products_layout, productPosition)
                      }
                      selectPrevious={() =>
                        selectPrev(plan.products_layout, productPosition)
                      }
                      selectedItem={
                        {
                          item: {
                            in_stock: true,
                            primary_candidate: {
                              product_id: selectedProductCompartment.id,
                            },
                          },
                        } as RealogramSelectedItem //TODO: select状態のためにindexを追加する
                      }
                      products={productsBulk?.products}
                      // 不必要な必須プロパティを空文字で渡しています。
                      referenceImage=""
                      // eslint-disable-next-line @typescript-eslint/no-empty-function -- 不必要な必須プロパティを渡すため
                      handleModalOpen={() => {}}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function -- 不必要な必須プロパティを渡すため
                      handleOpenReferenceImagePreview={() => {}}
                      isTenantSalesAnalytics={isTenantSalesAnalytics}
                    />
                  </Box>
                ) : (
                  <PlanogramOverview
                    term={dateTerm}
                    profitTab={profitTab}
                    setProfitTab={(profitTab: ProfitTab) => {
                      setProfitTab(profitTab);
                      updateAnalyticsQueryParams(profitTab);
                    }}
                    statistic={statistic}
                    isFlat={false}
                    displayAnalyticsData={planogramEstimatedData?.estimate}
                  />
                )}
                <CompartmentsTable
                  refs={listItemRefs}
                  products={productsBulk?.products}
                  productsLayout={plan.products_layout.slice().reverse()}
                  profitTab={profitTab}
                  handleClickListItem={handleClickListItem}
                  productSales={planogramEstimatedData?.estimate.products}
                />
              </Box>
            </Box>
          </>
        )}
      </DndProvider>
      <NotPermittedModal
        embedParam={embedParam ?? undefined}
        open={!!isForbidden}
        selectedDirectoryId={selectedDirectoryId}
        errorMessage="この棚割のアクセス権がありません"
        buttonMessage="棚割計画に戻る"
      />
    </Box>
  );
};
