import { CircularSpinner } from '@components/molecules';
import { Box, Typography } from '@mui/material';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppSelector } from '@store/index';
import { FC, MutableRefObject, useEffect } from 'react';
import { ProductCategory } from 'types/productCategories';
import { Product } from '../../../types/common';
import { ProductsGrid } from '../productsGrid/productsGrid';
import { ProductsSearchEmptyResults } from './fragments/productsSearchEmptyResults';
import { SalesEndSwitch } from '@components/molecules/salesEndSwitch/salesEndSwitch';
import { theme } from 'theme';
import { useLocation } from 'react-router-dom';
import { isPlans } from '@utils/planogram';

type Props = {
  products?: Product[];
  reset: () => void;
  filteredCategories?: ProductCategory[];
  handleClickProductCategory: (category: ProductCategory) => void;
  isLoading: boolean;
  productsRef: MutableRefObject<HTMLDivElement | null>;
  isDraggable?: boolean;
  handleSelectProductClick?: (productId: Product['id']) => void; // Note: actuals配下で利用
  isCompareMode?: boolean;
  handleEndReached: (index: number) => void;
  total?: number;
  handleClickProduct: (product: Product) => void;
  offset: number;
  selectedItemId?: number;
  handleChangeSalesEnded?: VoidFunction;
  isSalesEnded?: boolean;
  isSalesEndSwitchBorderTop?: boolean;
};

const layoutPadding = { pl: 2, pr: 1 };

export const ProductsSearchResults: FC<Props> = ({
  products,
  reset,
  filteredCategories,
  handleClickProductCategory,
  isLoading,
  productsRef,
  isDraggable = true,
  isCompareMode = false,
  handleEndReached,
  handleClickProduct,
  total,
  offset,
  selectedItemId,
  handleChangeSalesEnded,
  isSalesEnded = false,
  isSalesEndSwitchBorderTop = false,
}) => {
  const { productsListScrollPos } = useAppSelector(selectPlanogramEditorState);
  useEffect(() => {
    if (productsListScrollPos && productsRef.current) {
      productsRef.current.scrollTo({ top: productsListScrollPos });
    }
  }, [products?.length, productsListScrollPos, productsRef]);

  const handleCategoryClick = (category: ProductCategory) => {
    reset();
    handleClickProductCategory(category);
  };
  const { pathname } = useLocation();
  if (isLoading && offset === 0)
    return (
      <Box component="div" p={2}>
        <CircularSpinner />
      </Box>
    );
  if (products?.length === 0 && filteredCategories?.length === 0 && !isLoading)
    return <ProductsSearchEmptyResults />;

  return (
    <Box
      component="div"
      sx={{
        height: '100%',
        pb: `${
          isPlans(pathname)
            ? filteredCategories && selectedItemId
              ? '145px'
              : '60px'
            : selectedItemId
            ? '145px'
            : '80px'
        }`,
        ...(isDraggable ? {} : layoutPadding),
        overflow: 'hidden',
      }}
      ref={productsRef}
    >
      <Box
        component="div"
        mx={-2}
        borderTop={
          isSalesEndSwitchBorderTop
            ? `1px solid ${theme.palette.dividerBlack.medium}`
            : '0px'
        }
        borderBottom={`1px solid ${theme.palette.dividerBlack.medium}`}
        px={2}
      >
        <SalesEndSwitch
          isSalesEnded={isSalesEnded}
          handleChangeSalesEnded={() =>
            handleChangeSalesEnded && handleChangeSalesEnded()
          }
          isLoading={false}
        />
      </Box>
      <Box
        component="div"
        width="100%"
        ml={1}
        pt={1}
        color="#757575" // set color directly at this moment
        gap={2}
        display="flex"
      >
        <Typography>商品</Typography>
        <Typography>{total}件</Typography>
      </Box>
      <ProductsGrid
        products={products}
        handleClick={handleClickProduct}
        isLoading={isLoading}
        handleEndReached={handleEndReached}
        isDraggable={isDraggable}
        isCompareMode={isCompareMode}
        selectedItemId={selectedItemId}
        filteredCategories={filteredCategories}
        handleCategoryClick={handleCategoryClick}
      />
    </Box>
  );
};
