import { ModalInnerProps } from '@components/modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack, TextField, Typography } from '@mui/material';
import httpStatus from 'http-status';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { theme } from 'theme';
import { StoreBay } from 'types/storeBay';
import { InferType, object, string } from 'yup';
import { ModalActions } from '../modalActions/modalActions';
import { BayPlansSelect } from './bayPlansSelect';
import { ProductDivisionsSelect } from './productDivisionsSelect';
import { StoreSectionsSelect } from './storeSectionsSelect';

const nameErrorMessage = '他の什器名と重複している場合は、追加できません。';
const sortOrderErrorMessage = 'ソート番号は1以上の数字を入力してください。';
const sortNumberMax = 5;
const sortMaxErrorMessage = '5桁以内で入力してください。';

const schema = object({
  sortOrder: string()
    .test('is greater than 0', sortOrderErrorMessage, function (value) {
      // skip validation if no value
      if (!value) {
        return true;
      }
      return Number(value) > 0;
    })
    .required(''),
  name: string()
    .required()
    .matches(
      /^(?![\s\u3000]*$).*/,
      '半角スペースまたは全角スペースのみで登録することはできません。'
    ),
  productDivisionCode: string().required(),
  storeSectionMasterId: string(),
  bayPlanId: string(),
});
export type StoreBayFormData = InferType<typeof schema>;
export type StoreBayModalProps = {
  onSubmit: (data: StoreBayFormData, onSucces: VoidFunction) => Promise<void>;
  storeBay?: StoreBay;
  isEdit?: boolean;
};
type Props = ModalInnerProps<StoreBayModalProps>;

const textFieldErrorSx = {
  '& .Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: `${theme.palette.system.errorMain} !important`,
  },
  '& .Mui-error .MuiInputBase-input': {
    backgroundColor: `${theme.palette.system.errorBg}`,
  },
  '& input:-webkit-autofill': {
    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.system.errorBg} inset`,
  },
};

export const StoreBayModal: FC<Props> = ({
  handleClose,
  data: { storeBay, onSubmit, isEdit = false },
}) => {
  const [hasNameError, setHasNameError] = useState(false);
  const [hasSortMaxError, setHasSortMaxError] = useState(false);
  const methods = useForm<StoreBayFormData>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      name: storeBay?.name ?? '',
      sortOrder: storeBay?.sort_order.toString() ?? '',
      productDivisionCode:
        storeBay?.product_division.product_division_code.toString() ?? '',
      bayPlanId: storeBay?.bay_plan_id?.toString() ?? '',
      storeSectionMasterId: storeBay?.store_section?.id?.toString() ?? '',
    },
  });

  const onSubmitForm = async (data: StoreBayFormData) => {
    setHasNameError(false);
    setHasSortMaxError(false);
    onSubmit(data, handleClose).catch((error) => {
      if (error.status === httpStatus.CONFLICT) {
        setHasNameError(true);
      }
      if (
        error.status === httpStatus.INTERNAL_SERVER_ERROR &&
        methods.getValues().sortOrder.length > sortNumberMax
      ) {
        setHasSortMaxError(true);
      }
    });
  };
  return (
    <Box component="div">
      <Typography color={theme.palette.textBlack.secondary} variant="body2">
        *は必須項目です。
        <br />
        ソート番号は半角数字で入力してください。先頭の0は省略されます。
      </Typography>
      <Box
        component="form"
        width={452}
        onSubmit={methods.handleSubmit(onSubmitForm)}
        noValidate
        mt={2}
      >
        <Stack direction="column" gap={2}>
          <Controller
            name="sortOrder"
            control={methods.control}
            render={({ field, fieldState }) => {
              const errMsg = hasSortMaxError
                ? sortMaxErrorMessage
                : fieldState.error?.message;
              return (
                <TextField
                  {...field}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const isNumber = /^\d*$/.test(newValue);
                    if (hasSortMaxError && newValue.length < sortNumberMax) {
                      setHasSortMaxError(false);
                    }
                    if (isNumber) {
                      field.onChange(e);
                      if (newValue !== '') {
                        methods.trigger('sortOrder');
                      }
                      if (newValue === '') {
                        methods.clearErrors('sortOrder');
                      }
                    }
                  }}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  size="small"
                  fullWidth
                  label="ソート番号"
                  required
                  error={!!fieldState.error || hasSortMaxError}
                  helperText={errMsg}
                  sx={
                    !!fieldState.error || hasSortMaxError
                      ? textFieldErrorSx
                      : {}
                  }
                />
              );
            }}
          />
          <Controller
            name="name"
            control={methods.control}
            render={({ field, fieldState }) => {
              const errMsg = hasNameError
                ? nameErrorMessage
                : fieldState.error?.message;
              return (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  label="什器名"
                  required
                  error={!!fieldState.error || hasNameError}
                  helperText={errMsg}
                  sx={
                    !!fieldState.error || hasNameError ? textFieldErrorSx : {}
                  }
                />
              );
            }}
          />
          <ProductDivisionsSelect required methods={methods} />
          <StoreSectionsSelect methods={methods} />
          <BayPlansSelect methods={methods} />
        </Stack>
        <ModalActions
          handleClose={handleClose}
          actionBtnTitle={isEdit ? '変更' : '追加'}
          disabled={!methods.formState.isValid}
        />
      </Box>
    </Box>
  );
};
