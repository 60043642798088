import {
  RealogramDirectory,
  SearchRealogramDirectoryApiV1RealogramDirectoriesSearchGetParams,
} from '@api/types/generated';
import { realogramsApi } from '@api/services/realogramDirectories';
import { useInfiniteQuery } from '@tanstack/react-query';
import { rowsPerPage } from '@utils/const';

export const realogramDirectorySearchQueryKey = 'search_realograms';

type Params =
  SearchRealogramDirectoryApiV1RealogramDirectoriesSearchGetParams & {
    enabled?: boolean;
  };

export const useRealogramDirectoriesSearch = ({
  enabled = false,
  ...params
}: Params) => {
  return useInfiniteQuery({
    enabled,
    queryKey: [realogramDirectorySearchQueryKey, params],
    queryFn: ({ pageParam }) => {
      return realogramsApi.getSearchRealogramDirectories({
        ...params,
        offset: pageParam,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      const limit = lastPage.pager.limit ?? 0;
      const total = lastPage.pager.total ?? 0;
      const offset = lastPage.pager.offset ?? 0;
      if (offset === total || limit >= total) return undefined;
      return offset + rowsPerPage;
    },
    select: (data) => {
      return data?.pages?.reduce((acc, curr) => {
        acc.push(...curr.realogram_directories);
        return acc;
      }, [] as RealogramDirectory[]);
    },
    refetchOnWindowFocus: false,
  });
};
