import { getColumns } from '@components/pages/realograms/fragments/realogramsDirectoryTable/directoryTableHeaderData';
import { TableCells } from '@components/pages/realograms/fragments/realogramsDirectoryTable/tableCells';
import { TableHeader } from '@components/pages/realograms/fragments/realogramsDirectoryTable/tableHeader';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { selectUser } from '@reducers/auth/selectors';
import { useAppSelector } from '@store/index';
import { FC } from 'react';
import {
  RealogramDirectory,
  RealogramListOrder,
  RealogramOrder,
} from 'types/realogram';
import { RealogramSharePermissionProps } from 'types/sharePermission';
import { DataTable } from './table';
import { TableRowWrapper } from './tableRowWrapper';

type Props = {
  data?: RealogramDirectory[];
  isDisplayLoadingSkeleton: boolean;
  isEmpty: boolean;
  firstOrder: RealogramOrder;
  isRefetching: boolean;
  isNoHit: boolean;
  handleRowClick: (item: RealogramDirectory) => void;
  handleFavoriteClick: (id: string, isFavorite: boolean) => void;
  handleSharePermission: (item: RealogramSharePermissionProps) => void;
  handleEndReached: (isAtBottom: boolean) => void;
};

export const RealogramDirectoriesTable: FC<Props> = ({
  data,
  isDisplayLoadingSkeleton,
  isEmpty,
  firstOrder,
  isRefetching,
  isNoHit,
  handleRowClick,
  handleFavoriteClick,
  handleSharePermission,
  handleEndReached,
}) => {
  const user = useAppSelector(selectUser);
  const { updateQueryParameter, removeQueryParameters } = useUrlQueryParams();
  const columns = getColumns(false);
  const handleChangeOrder = (order?: RealogramListOrder) => {
    if (!order) return removeQueryParameters(['first_order']);
    updateQueryParameter('first_order', order ?? '');
  };

  return (
    <DataTable<RealogramDirectory>
      isRefetching={isRefetching}
      isEmpty={isEmpty}
      isDisplayLoadingSkeleton={isDisplayLoadingSkeleton}
      data={data}
      columns={columns}
      isNoHit={isNoHit}
      handleEndReached={handleEndReached}
      tableHeaderContent={
        <TableHeader
          columns={columns}
          firstOrder={firstOrder}
          handleChangeOrder={(order?: RealogramListOrder) =>
            handleChangeOrder(order)
          }
        />
      }
      itemContent={(_, item) => {
        const isStarred = item.favorite?.owner_id === user?.id;
        return (
          <TableCells
            isFilteredByViewed={false}
            realogramDirectory={item}
            handleOpenDeleteDialog={() => void 0}
            handleFavoriteClick={() => handleFavoriteClick(item.id, isStarred)}
            handleSharePermission={() =>
              handleSharePermission({
                directoryId: item.id,
                type: item.type,
              })
            }
          />
        );
      }}
      renderTableRow={(props) => {
        // eslint-disable-next-line react/prop-types -- prop types is outdated
        const isStoreBay = !!props.item.store_bay_id;
        return (
          <TableRowWrapper
            handleRowClick={() => {
              if (isStoreBay) return;
              handleRowClick(
                // eslint-disable-next-line react/prop-types -- prop types is outdated
                props.item as RealogramDirectory
              );
            }}
            {...props}
          />
        );
      }}
    />
  );
};
