import { ArrowForward } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import { getColor, getDiffValueWithSign, getTypographyStyle } from './utils';
import { getProfitTagName } from '@utils/const';

export type StatisticType = 'increase' | 'decrease' | 'neutral';
export type ColumnType = 'negative' | 'positive' | 'neutral';

type Props = {
  title: string;
  columnType: ColumnType;
  prevValue?: string; //TODO: replace with api definition
  value: string; //TODO: replace with api definition
  percent?: string; //TODO: replace with api definition
  diffValue: string;
  statisticType: StatisticType;
  isPrevValueEmpty?: boolean;
  isValueEmpty?: boolean;
  isTotalGrossSalesPrice?: boolean;
};

export const StatisticBox: FC<Props> = ({
  title,
  columnType,
  prevValue,
  value,
  percent,
  diffValue,
  statisticType,
  isPrevValueEmpty = false,
  isValueEmpty = false,
  isTotalGrossSalesPrice = false,
}) => {
  return (
    <Box
      component="div"
      width="100%"
      display="flex"
      gap="4px"
      flexDirection="column"
    >
      <Typography
        color={theme.palette.textBlack.secondary}
        sx={{
          ...theme.typography.body3,
        }}
      >
        {getProfitTagName(title)}
      </Typography>
      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end',
        }}
      >
        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box
            component="div"
            display="flex"
            flexDirection={isTotalGrossSalesPrice ? 'column' : 'row'}
            gap="4px"
            flexWrap="wrap"
            width="100%"
            sx={{
              ...theme.typography.body2,
            }}
          >
            <Box
              component="div"
              display="flex"
              gap="4px"
              flexWrap="wrap"
              alignItems="center"
            >
              <Typography
                sx={{
                  ...getTypographyStyle(isPrevValueEmpty),
                }}
              >
                {prevValue}
              </Typography>
              <ArrowForward
                sx={{
                  width: 16,
                  height: 16,
                  color: theme.palette.icons.primary,
                }}
              />
              <Typography
                sx={{
                  ...getTypographyStyle(isValueEmpty),
                }}
              >
                {value}
              </Typography>
            </Box>
            {diffValue && (
              <Box
                component="div"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flex="1"
                sx={{
                  ...theme.typography.body2,
                }}
              >
                <Box component="div" display="flex">
                  <Typography
                    sx={{
                      ...theme.typography.body2,
                    }}
                  >
                    (
                  </Typography>
                  <Typography
                    color={getColor(
                      columnType,
                      statisticType,
                      diffValue,
                      title
                    )}
                    sx={{
                      ...theme.typography.subtitle2,
                    }}
                  >
                    {getDiffValueWithSign(statisticType, diffValue)}
                  </Typography>
                  <Typography
                    sx={{
                      ...theme.typography.body2,
                    }}
                  >
                    )
                  </Typography>
                </Box>
                {percent && (
                  <Typography
                    color={theme.palette.textBlack.secondary}
                    sx={{
                      ...theme.typography.caption1,
                    }}
                  >
                    {getDiffValueWithSign(statisticType, percent)}%
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
