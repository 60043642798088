import { EmptyResult } from '@components/molecules/emptyResult/emptyResult';
import { GramTableFoot } from '@components/organisms';
import { GramTableFooter } from '@components/organisms/gramTableFooter/gramTableFooter';
import { ColumnProps } from '@components/pages/realograms/fragments/realogramsDirectoryTable/tableHeader';
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableProps,
  TableRow,
} from '@mui/material';
import { rowHeight, rowsPerPage } from '@utils/const';
import { ReactNode, useRef } from 'react';
import { ItemProps, TableVirtuoso, TableVirtuosoHandle } from 'react-virtuoso';

export type TableRowProps<T> = ItemProps<T>;
type Props<T> = {
  data?: T[];
  columns: ColumnProps[];
  tableHeaderContent: ReactNode;
  isDisplayLoadingSkeleton: boolean;
  isEmpty: boolean;
  isRefetching: boolean;
  isNoHit: boolean;
  itemContent: (index: number, item: T) => ReactNode;
  renderTableRow?: (props: TableRowProps<T>) => ReactNode;
  handleEndReached: (isAtBottom: boolean) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- could be any data
export const DataTable = <T extends Record<string, any>>({
  data,
  columns,
  tableHeaderContent,
  isDisplayLoadingSkeleton,
  isEmpty,
  isRefetching,
  isNoHit,
  itemContent,
  handleEndReached,
  renderTableRow = (props: TableRowProps<T>) => (
    <TableRow sx={{ height: rowHeight }} {...props} />
  ),
}: Props<T>) => {
  const ref = useRef<TableVirtuosoHandle>(null);
  return (
    <TableVirtuoso
      ref={ref}
      style={{
        border: '1px solid #CCCCCC',
      }}
      data={
        data?.length
          ? data
          : Array.from({ length: rowsPerPage }, () => ({} as T))
      }
      atBottomStateChange={handleEndReached}
      components={{
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        Table: (props: TableProps) => (
          <Table
            {...props}
            style={{
              borderCollapse: 'separate',
              height: isEmpty ? '100%' : '',
            }}
          />
        ),
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableHead,
        // Note: TableRowに一行に対するスタイルや動作を設定し、itemContentに行内の各カラムに対するスタイルや動作設定する。
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableRow: (props) => {
          return (
            <>
              {isDisplayLoadingSkeleton ? (
                <TableRow {...props}>
                  {columns.map((_, index) => {
                    return (
                      <TableCell key={index}>
                        <Skeleton animation="wave" width="75%" />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ) : (
                <>{renderTableRow(props as TableRowProps<T>)}</>
              )}
            </>
          );
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableBody:
          isEmpty && !isDisplayLoadingSkeleton
            ? () => EmptyTable(isNoHit)
            : TableBody,

        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        ScrollSeekPlaceholder: () => (
          <TableRow>
            {columns.map((_, index) => {
              return (
                <TableCell key={index}>
                  <Skeleton animation="wave" width="75%" />
                </TableCell>
              );
            })}
          </TableRow>
        ),
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableFoot: GramTableFoot,
      }}
      fixedHeaderContent={() => tableHeaderContent}
      fixedFooterContent={() => {
        if (isRefetching) return <GramTableFooter columns={columns} />;
        return <></>;
      }}
      itemContent={(index, item) => itemContent(index, item)}
    />
  );
};

const EmptyTable = (isNoHit: boolean) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={12}>
          <EmptyResult
            title="什器がありません"
            message={
              isNoHit
                ? '条件を変更してください。'
                : '左上の「什器追加」ボタンから追加してください。'
            }
            isErrorIcon
          />
        </TableCell>
      </TableRow>
    </TableBody>
  );
};
