import { paths } from 'types/api';
import { baseApi, KeysToCamelcase, StoreBayTags } from '../baseApi';
import { objectToSnake } from 'ts-case-convert';

export type GetStoresParams = KeysToCamelcase<
  paths['/api/v1/stores']['get']['parameters']['query'] & {
    filter_by_role?: string;
  } // TODO: delete this intersection when api.ts is regenrated
>;
export type GetStoresResponse =
  paths['/api/v1/stores']['get']['responses']['200']['content']['application/json'];
type GetStoreParams = KeysToCamelcase<
  paths['/api/v1/stores/{store_id}']['get']['parameters']['path']
>;
export type GetStoreResponse =
  paths['/api/v1/stores/{store_id}']['get']['responses']['200']['content']['application/json'];

type ListStoreBaysParams = KeysToCamelcase<
  paths['/api/v1/stores/{store_id}/store_bays']['get']['parameters']['path']
>;
export type ListStoreBaysResponse =
  paths['/api/v1/stores/{store_id}/store_bays']['get']['responses']['200']['content']['application/json'];

type ProductDivisionsResponse =
  paths['/api/v1/product_divisions']['get']['responses']['200']['content']['application/json'];

type StoreSectionsResponse =
  paths['/api/v1/store_sections']['get']['responses']['200']['content']['application/json'];

type CreateStoreBayParams = KeysToCamelcase<
  paths['/api/v1/store_bays']['post']['requestBody']['content']['application/json']
>;
type CreateStoreBayResponse =
  paths['/api/v1/store_bays']['post']['responses']['200']['content']['application/json'];

type UpdateStoreBayParams = KeysToCamelcase<
  paths['/api/v1/store_bays/{store_bay_id}']['put']['requestBody']['content']['application/json'] & {
    id: number;
  }
>;
type UpdateStoreBayResponse = KeysToCamelcase<
  paths['/api/v1/store_bays/{store_bay_id}']['put']['responses']['200']['content']['application/json']
>;

type DeleteStoreBayParams = KeysToCamelcase<
  paths['/api/v1/store_bays/{store_bay_id}']['delete']['parameters']['path']['store_bay_id']
>;

// see) https://staging.shelf.pfn.dev/docs#/stores
const storesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    listStores: builder.query<GetStoresResponse, GetStoresParams>({
      query: (params) => ({
        url: 'stores',
        params: {
          offset: params?.offset,
          limit: params?.limit,
          filter_by_role: params?.filterByRole,
        },
      }),
    }),
    getStore: builder.query<GetStoreResponse, GetStoreParams>({
      query: (params) => ({
        url: `stores/${params.storeId}`,
      }),
    }),
    listStoreBays: builder.query<ListStoreBaysResponse, ListStoreBaysParams>({
      query: (params) => ({
        url: `stores/${params.storeId}/store_bays`,
      }),
      providesTags: [StoreBayTags.ListStoreBays, StoreBayTags.DeleteStoreBay],
    }),
    productDivisions: builder.query<ProductDivisionsResponse, void>({
      query: () => ({
        url: `product_divisions`,
      }),
    }),
    storeSections: builder.query<StoreSectionsResponse, void>({
      query: () => ({
        url: `store_sections`,
      }),
    }),
    createStoreBay: builder.mutation<
      CreateStoreBayResponse,
      CreateStoreBayParams
    >({
      query: (body) => ({
        url: 'store_bays',
        method: 'POST',
        body: objectToSnake(body),
      }),
      invalidatesTags: [StoreBayTags.ListStoreBays],
    }),

    updateStoreBay: builder.mutation<
      UpdateStoreBayResponse,
      UpdateStoreBayParams
    >({
      query: ({ id, ...body }) => ({
        url: `store_bays/${id}`,
        method: 'PUT',
        body: objectToSnake(body),
      }),
      invalidatesTags: [StoreBayTags.ListStoreBays],
    }),

    deleteStoreBay: builder.mutation<void, DeleteStoreBayParams>({
      query: (storeBayId) => ({
        url: `store_bays/${storeBayId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [StoreBayTags.DeleteStoreBay],
    }),
  }),
});

export const {
  useListStoresQuery,
  useLazyListStoresQuery,
  useGetStoreQuery,
  useListStoreBaysQuery,
  useProductDivisionsQuery,
  useStoreSectionsQuery,
  useCreateStoreBayMutation,
  useUpdateStoreBayMutation,
  useDeleteStoreBayMutation,
} = storesApi;
